<template>
	<div>
		<!--begin::Dashboard-->
		<div class="row">
			<div class="col-xxl-8 pr-0">
				<DashStats />
			</div>
			<div class="col-xxl-4 pl-2">
				<MessagingYellow title="Managed Services" subTitle="New from Marlink VSAT Portal" />
			</div>
		</div>
		<div class="row" v-for="(row, rindex) in bootlayout" v-bind:key="rindex">
			<DashWidget
				:dl="col"
				:class="['col-xl-' + 12 / (4 / col.sizeX), 'margin-bottom-20']"
				style="min-height: 0;"
				v-for="(col, cindex) in row"
				v-bind:key="cindex"
			/>
		</div>

		<!--end::Dashboard-->
	</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { SET_READY_COMPONENTS, SET_READY_STATE } from '@/core/services/store/ready.module';
import { GET_USER_ALERT_BANNER, CLEAR_BANNER } from '@/core/services/store/notification.module';
import swMixin from '@/core/services/mixins/serviceworker.mixin';
import { mapGetters } from 'vuex';

export default {
	name: 'dashboard',
	mixins: [swMixin],
	components: {
		DashStats: () => import('@/view/content/widgets/dashboard/DashStats.vue'),
		DashWidget: () => import('@/view/content/widgets/dashboard/DashWidget.vue'),
		MessagingYellow: () => import('@/view/content/widgets/dashboard/MessagingYellow.vue'),
	},
	data() {
		return {
			dashboardLayout: [],
			bootlayout: {},
		};
	},
	computed: {
		...mapGetters(['currentUser']),
	},
	methods: {
		setBootLayout() {
			this.dashboardLayout.sort((a, b) => b.row - a.row || b.col - a.col);
			this.bootlayout = {};
			// var treeviewrow = null;
			for (let d of this.dashboardLayout) {
				if (!this.bootlayout[d.row]) {
					this.bootlayout[d.row] = {};
				}

				// treeview is gone so make whatever widget on the same row as it full size
				// if (d.WidgetId == 1000) {
				// 	treeviewrow = d.row;
				// } else {
				this.bootlayout[d.row][d.col] = d;
				// }
			}
			// if (treeviewrow !== null) {
			// 	Object.keys(this.bootlayout).forEach(r => {
			// 		if (r == treeviewrow) {
			// 			if (Object.keys(this.bootlayout[r]).length == 1) {
			// 				Object.keys(this.bootlayout[r]).forEach(c => {
			// 					this.bootlayout[r][c].sizeX = 4;
			// 				});
			// 			}
			// 		}
			// 	});
			// }
		},
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Dashboard' }]);
		this.$store.commit(SET_READY_COMPONENTS, ['DashStats', 'SiteList', 'DashboardLayoutAPI']);
		this.$http.get('dashboardlayout').then(resp => {
			this.dashboardLayout = resp.data.data;
			this.setBootLayout();
			this.$store.commit(SET_READY_STATE, { component: 'DashboardLayoutAPI', ready: true });
		});
		this.setSWListener('dashboardlayout', data => {
			this.dashboardLayout = data.data;
			this.setBootLayout();
		});
	},
	created() {
		this.$store.dispatch(GET_USER_ALERT_BANNER, { AccountID: this.currentUser.AccountID });
	},
	beforeDestroy() {
		this.$store.commit(CLEAR_BANNER);
	},
};
</script>
